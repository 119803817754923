.modalContent {
  border-radius: 1.25rem;
  padding: 0 1.25rem;

  .modalBody {
    display: flex;
    flex-direction: column;

    .close {
      text-align: right;
      font-size: 25px;
      cursor: pointer;
    }

    span {
      padding-right: 10px;
    }

    .btnConfirm {
      display: flex;
      justify-content: end;
      margin-top: 15px;

      button {
        font-style: normal;
        font-weight: 500;
      }

      button:nth-child(1) {
        width: 110px;
        border: 1px solid #dddddd;
        border-radius: 10px;
        margin-right: 10px;

        background: #dddddd;
      }

      button:nth-child(2) {
        min-width: 110px;
        width: auto;
        border-radius: 10px;
        background: #B22222;
        color: #ffffff;
        border: none;
      }
    }
  }

}
