.container {
  width: 100%;
  height: 100vh;

    h1 {
      font-weight: 500;
      font-size: 1.75rem;
      color: #1E1E1E;
    }

    .searchProducts {
      width: 100%;
      background-color: #FFFFFF;
      margin-top: 20px;
      padding: 16px;
      border-radius: 10px;


      .alignInput {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 25px;

        .input {
          height: 37px;
        }


        .groupInput {
          > div {
            > div {
              > div {
                > div {
                  color: rgba(0, 0, 0, 0.7);
                  &:first-child {
                    max-width: 90%;
                  }
                }

              }
            }
          }
          > div {
            width: 250px;

          }
        }
        .error {
          color: red;
          font-size: 0.75rem;
          width: 300px;
        }

        input {
          height: auto;
          outline: 0;
          border: 1px solid #C5C5C5;
          border-radius: 6px;
          padding: 10px;

          &::placeholder {
            color: #C5C5C5;
          }
        }
      }

      .alignButtonSearch {
        margin-top: 35px;
        display: flex;
        flex-direction: row-reverse;
      }
    }

    .alignButtonCsvRegister {
      display: flex;
      margin: 20px 16px;
      align-items: center;
      justify-content: space-between;
    }

    .tableProducts {
      width: 100%;
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 20px;
      position: relative;
      overflow-x: auto;
      overflow-y: auto;
      max-height: 555px;
      min-height: 555px;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      th, td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #F3F3F3;
        font-size: 0.875rem;
      }

      td {
        height: 70px;
        color: #656565;
        text-transform: capitalize;

        .icon {
          display: block;
          max-width: 100%;
          width: 50px;
          object-fit: cover;
          
          height: auto;
        }

        .iconButton {
          
          border-radius: 8px;
          padding: 10px;
        }
      }

      .title {
        width: 40%;
      }

      .editProducts {
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        outline: 0;

        path:hover {
          fill: #2b6cde;
        }
      }

      .publicTenderCount {
        border: none;
        border-radius: 8px;
        padding: 6px 12px;
        animation: all 0.2s ease;
        &:hover {
          filter: brightness(0.96);
        }
      }
    }


}



.alignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.dNone {
  display: none;
}