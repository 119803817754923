.container {
  width: 100%;
  height: 100%;

  h1 {
    font-weight: 500;
    font-size: 1.75rem;
    color: #1E1E1E;

    @media (max-width: 1024px) {
      padding: 10px;
    }
  }

  .contentGraph {
    display: flex;
    justify-content: space-between;
    margin-top: 23px;
    width: 100%;
    height: 355px;
    gap: 44px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      height: auto;
    }

    .salesLastMonths {
      width: 100%;
      height: 100%;
      padding: 18px 14px 30px 14px;
      background-color: #ffffff;
      border-radius: 6px;

      @media (max-width: 768px) {
        padding: 10px;
      }

      h2 {
        font-weight: 500;
        font-size: 1rem;
      }
    }

    .topFiveBooks {
      width: 100%;
      height: 100%;
      padding: 18px 25px 18px 22px;
      background-color: #ffffff;
      border-radius: 6px;

      @media (max-width: 768px) {
        padding: 10px;
      }

      h2 {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }

  .contentStatistics {
    display: flex;
    height: 182px;
    width: 100%;
    gap: 89px;
    margin-top: 35px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0;
      margin-top: 35px;
    }

    .salesLastDays {
      display: flex;
      justify-content: space-between;
      width: 327px;
      height: 158px;
      border-radius: 6px;
      background-color: #ffffff;
      padding: 30px 23px 27px 32px;
    }

    .totalIncomeGraph {
      display: flex;
      justify-content: space-between;
      width: 396px;
      height: 158px;
      border-radius: 6px;
      background-color: #ffffff;
      padding: 30px 32px 32px 32px;
      margin-top: 24px;
    }
  }

  .contentStatisticsIncome {
    display: flex;
    flex-direction: row-reverse;
    height: 158px;
    width: 100%;
    margin-top: 50px;

    @media (max-width: 768px) {
      flex-direction: initial;
      margin-top: 200px;
    }

    .totalIncomePlus {
      display: flex;
      justify-content: space-between;
      width: 396px;
      height: 158px;
      border-radius: 6px;
      background-color: #ffffff;
      padding: 30px 32px 32px 32px;
    }
  }

  .alignLeft {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .subtitle {
      font-size: 1rem;
      font-weight: 500;
      color: #656565;
    }

    .value {
      font-size: 1.5rem;
      font-weight: 500;
      color: #1E1E1E;
    }

    .valueUpOrDown {
      font-size: 1rem;
      font-weight: 500;
      margin-top: 10px;
    }

    .valuePositive {
      color: #28C66F;
    }

    .valueNegative {
      color: #EC5453;
    }
  }
}


