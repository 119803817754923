.container {
  display: flex;
  height: 100%;
  gap: 26px;

  .content {
    flex: 1;

    .title {
      display: flex;
      align-items: center;
      gap: 24px;

      h1 {
        color: #1e1e1e;
        font-size: 28px;
        font-weight: 500;
      }

      .btnReturn {
        display: flex;
        width: 115px;
        height: 25px;
        padding: 4px 25.5px 5px 25.5px;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        color: #656565;
        border: 1px solid #c5c5c5;
        text-decoration: none;
      }
    }

    .box {
      width: 100%;
      height: auto;
      padding: 19px 21px;
      border-radius: 10px;
      background: #fff;
      margin-top: 20px;

      .boxTitle {
        color: #1e1e1e;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 7px;
        margin-bottom: 10px;
      }


      .groupData {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0px 40px;
        @media (max-width: 1110px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 900px) {
          grid-template-columns: 1fr;
        }

        &.occupations {
          gap: 40px;
        }

        .groupInput {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          width: 100%;

          label {
            color: #656565;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 6px;

            span {
              font-size: 12px;
              color: #999;
            }
          }

          div {
            div {
              div {
                div {
                  input {
                    height: 100%;
                  }
                }
              }
            }
          }

          input {
            width: 100%;
            height: 37px;
            outline: 0;
            border: 1px solid #c5c5c5;
            border-radius: 6px;
            padding: 10px;

            &::placeholder {
              color: #c5c5c5;
            }
          }

          input[type="file"] {
            position: absolute;
            inset: 0;
            opacity: 0;
          }

          .fileInput {
            display: flex;
            align-items: center;
            gap: 12px;
            position: relative;
            width: 100%;
            max-height: 37px;
            outline: 0;
            border-radius: 6px;

            span {
              display: flex;
              gap: 10px;
              align-items: center;
              width: 100%;
              max-height: 37px;
              background-color: #eef4ff;
              padding: 7px 20px;
              border-radius: 8px;
              border: 1px solid #2b6cde;
              color: #2b6cde;
            }

            span p {
              margin: 0;
              padding: 0;
              max-width: 22ch;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

            }
          }

          input[type="checkbox"] {
            width: 16px;
            height: 16px;
            border: 1px solid #c5c5c5;

            position: relative;
            margin: 0;
          }

          textarea {
            min-height: 158px;
            max-width: 1500px;
          }
        }

        .alignCheckbox {
          display: flex;
          gap: 40px;
          width: 100%;
        }

        .urlNoticeContainer {
          display: flex;
          max-width: 300px;
          gap: 6px;
          margin-bottom: 8px;

          .urlNotice {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 20ch;
            color: #2b6cde;
          }
        }

      }

      table {
        width: 100%;
        padding: 0 22px;
        margin: 20px 0;
        font-size: 16px;

        th,
        td {
          border-bottom: 1px solid #f3f3f3;
          padding: 10px 0;
          text-align: left;
        }

        thead {
          th {
            color: #1e1e1e;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-top: 0;
          }
        }

        tbody {
          td {
            color: #656565;
            font-size: 14px;
            font-weight: 400;
            height: 45px;

            a {
              color: #2f80ed;
              font-size: 14px;
            }

            span {
              color: #c5c5c5;
            }

            .btnSend {
              cursor: pointer;
              border-radius: 6px;
              border: 1px solid #f07c00;
              background: #fbd9b6;
              display: flex;
              width: 115px;
              padding: 4px 25px 5px 25px;
              color: #f07c00;
              font-size: 12px;
              font-weight: 500;
              align-items: center;
              justify-content: space-around;

              &:hover {
                background: #f3a655;
                color: #fff;
                transition: 0.4s ease-in-out;
              }
            }
          }
        }
      }

      .statusGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px 0 30px;
        position: relative;
        gap: 75px;
        @media (max-width: 768px) {
          flex-direction: column;
          gap: 20px;
        }

        .statusText {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 12px;
          position: relative;

          .active {
            color: #f07c00;
            font-size: 14px;
            font-weight: 400;
          }

          p {
            color: #656565;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .message {
          display: inline-flex;
          height: 153px;
          padding: 0 15px 0 30px;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #f07c00;
          background: #fbd9b6;
          color: #1e1e1e;
          font-size: 14px;
          width: 445px;
          line-height: normal;
          position: relative;
          @media (max-width: 768px) {
            width: 100%;
            height: auto;
          }
        }

        .activeStatus::after {
          content: "";
          position: absolute;
          margin-top: 8px;
          left: 100%;
          width: calc(100% + 50px);
          height: 1px;
          background-color: #f07c00;
        }
      }

      .toggleStatus {
        display: flex;
        gap: 13px;

        p {
          color: #000;
          font-size: 14px;
          font-weight: 400;
        }

        .groupAlignStatus {
          display: flex;
          align-items: center;

          label {
            color: #1e1e1e;
            font-size: 14px;
            margin-left: 2px;
          }

          input[type="radio"] {
            accent-color: #f07c00; /* For modern browsers supporting accent-color */
          }
        }
      }

      /* Fallback for browsers that do not support accent-color */
      input[type="radio"] {
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #c5c5c5;
        border-radius: 50%;
        position: relative;
        margin: 0;
      }

      input[type="radio"]:checked::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background-color: #f07c00;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 2px;
      }

      .alignButtons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btnDelete {
          border-radius: 6px;
          border: 1px solid #eb5757;
          background: none;
          width: 115px;
          height: 25px;
          color: #eb5757;
          font-size: 12px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          cursor: pointer;
        }
      }

      &.occupations {
        max-height: 1000px;
        overflow-y: auto;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
}

.alignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

:global(.ql-toolbar.ql-snow) {
  border-radius: 6px 6px 0 0;
}

:global(.ql-container.ql-snow) {
  border-radius: 0 0 6px 6px;
}
