* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.custom-multiselect .p-multiselect {
  width: 100%;
  height: 37px;
  border: 1px solid #C5C5C5;
  border-radius: 6px;
  padding: 0 10px; /* Ajuste do padding */
  outline: none;
}

.custom-multiselect .p-multiselect-label {
  line-height: 37px;
  color: #333;
}

.custom-multiselect .p-multiselect-trigger {
  height: 100%; /* Garante que o botão do dropdown ocupe a altura total */
  border-left: 1px solid #C5C5C5;
}

.custom-multiselect .p-multiselect-panel {
  border: 1px solid #C5C5C5;
  border-radius: 6px;
  box-shadow: none; /* Remova a sombra padrão do dropdown */
}

.custom-multiselect .p-multiselect-filter-container {
  padding: 5px 10px; /* Ajuste o padding do filtro */
}

.custom-multiselect .p-checkbox {
  margin-right: 10px;
}



.css-1fso86p::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.css-1fso86p::-webkit-scrollbar-track {
  background: #f1f1f1;        /* color of the tracking area */
}

.css-1fso86p::-webkit-scrollbar-thumb {
  background-color: #4c4c4c;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #f1f1f1;  /* creates padding around scroll thumb */
}

