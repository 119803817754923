.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #FAFAFA;

  @media (max-width: 1300px) {
    display: block;
  }

  .align {
    display: flex;
    align-items: center;
    gap: 74px;
    height: 100vh;


    @media (max-width: 1300px) {
      flex-direction: column;
      padding: 20px;
    }

    .svgNotFoundImage {
      width: 237px;
      height: 224px;

      @media (min-width: 768px) {
        width: 350px;
        height: 260px;
      }

      @media (min-width: 1300px) {
        width: 572px;
        height: 541px;
      }
    }

    .textError {
      width: 600px;

      @media (max-width: 1300px) {
        width: 100%;
      }

      h1 {
        font-size: 44px;
      }

      span {
        font-weight: 400;
        font-size: 1.5rem;
      }

      .button {
        margin: 45px auto auto 64.5px;

        @media (max-width: 1300px) {
          margin: 80px auto auto 0;
        }
      }
    }
  }
}