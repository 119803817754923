.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #FAFAFA;

  .alignTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 50px;

    .loginTitle {
      font-size: 44px;
      font-weight: 600;
      color: #333;
      line-height: 38px;

      span {
        color: #2b6cde;
      }
    }
  }

  p {
    font-size: 0.875rem;
  }

  .svgLogo {
    width: 200px;
    height: 55px;
  }

  .align {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 464px;
    height: 327px;
    box-shadow: 0 4px 20px -2px #3232471A;
    margin-top: 30px;
    background: #ffff;
    border-radius: 6px;

    @media (max-width: 769px) {
      width: 330px;
      height: 303px;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: center;

      .titleGroup {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;

        h1 {
          font-size: 1.125rem;
          font-weight: 500;
          color: #1E1E1E;
        }

        span {
          margin-top: 18px;
          color: #656565;
          font-size: 0.875rem;
          line-height: 19px;
          letter-spacing: 0.5px;
        }
      }

      .inputContainer, .passwordContainer {
        margin-bottom: 15px;

        .error {
          color: red;
          font-size: 0.75rem;
          width: 300px;
          margin-top: 2px;
        }

        .inputText {
          width: 400px;
          height: 37px;
          padding: 9px 10px 9px 10px;
          border: 1px solid #C5C5C5;
          border-radius: 6px;
          outline: 0;
          margin-bottom: 3px;
          @media (max-width: 769px) {
            width: 300px;
            height: 37px;
          }

          &::placeholder {
            color: #C5C5C5;
            opacity: 1;
          }
        }
      }

      .passwordContainer {
        position: relative;
        width: 400px;
        margin-bottom: 19px;
        @media (max-width: 769px) {
          width: 300px;
        }

        .inputText {
          width: 100%;
          padding-right: 40px;
        }

        img {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          cursor: pointer;
          width: 16px;
          height: 16px;
        }
      }

      .remember {
        display: flex;
        align-items: center;
        max-width: 400px;
        width: 100%;
        margin-top: 4px;
        @media (max-width: 769px) {
          max-width: 300px;
        }

        input[type="checkbox"] {
          display: none;
          border: 1px solid #C5C5C5;

          &:checked + label::before {
            background-color: #510071;
            border-color: #C5C5C5;
            content: '✓';
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
          }
        }

        label {
          display: flex;
          align-items: center;
          cursor: pointer;

          color: #1E1E1E;
          font-size: 14px;
          font-weight: 400;

          &::before {
            content: '';
            width: 20px;
            height: 20px;
            border: 1px solid #C5C5C5;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
        }

        .checkboxLabel {
          font-size: 0.875rem;
          color: #1E1E1E;
        }
      }

      Button {
        margin-top: 15px;
        font-size: 0.875rem;
        font-weight: 600;
        width: 400px;
        height: 39px;

        @media (max-width: 769px) {
          width: 300px;
        }
      }

      a {
        margin-top: 21px;
        color: #656565;
        text-decoration: underline;
        font-size: 0.875rem;
      }
    }
  }
}
