.groupData {


  .course {
    background-color: #ffffff;
    border: 1px solid #c5c5c5;
    padding: 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    &:hover {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }

    .header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 6px;
      border-bottom: 1px solid #f1f1f1;

      span {
        width: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .examNumber {

      font-size: 0.9rem;
    }

    .groupInput {
      display: grid;
      margin-bottom: 10px;
      width: 100%;

      label {
        color: #656565;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 6px;
      }

      div {
        text-transform: capitalize;

      }

      div {
        div {
          div {
            div {
              input {
                height: 100%;
              }
            }
          }
        }
      }

      input {
        width: 100%;
        height: 37px;
        outline: 0;
        border: 1px solid #c5c5c5;
        border-radius: 6px;
        padding: 10px;

        &::placeholder {
          color: #c5c5c5;
        }
      }

      input[type="file"] {
        position: absolute;
        inset: 0;
        opacity: 0;
      }

      .fileInput {
        display: flex;
        align-items: center;
        gap: 12px;
        position: relative;
        width: 100%;
        max-height: 37px;
        outline: 0;
        border-radius: 6px;

        span {
          display: flex;
          gap: 10px;
          align-items: center;
          width: 100%;
          max-height: 37px;
          padding: 7px 20px;
          border-radius: 6px;
          border: 1px solid #c5c5c5;
          background-color: #fff;
        }

        span p {
          margin: 0;
          padding: 0;
          max-width: 22ch;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        border: 1px solid #c5c5c5;

        position: relative;
        margin: 0;
      }


    }

    .urlNoticeContainer {
      display: flex;
      max-width: 300px;
      gap: 6px;
      margin-bottom: 8px;

      .urlNotice {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20ch;
        color: #2b6cde;
      }
    }

    .delete {
      place-self: flex-end;
      text-decoration: underline;
      cursor: pointer;
      font-size: 0.9rem;
      color: rgb(255, 93, 93);
    }
  }

  .alignCheckbox {
    display: flex;
    gap: 40px;
    width: 100%;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 30px;
  font-size: 1rem;
  font-weight: 400;
  background-color: #eef4ff;
  border: 1px solid hsl(218, 73%, 52%);
  color: #2b6cde;
  cursor: pointer;
  border-radius: 10px;
  outline: 0;
  margin-top: 20px;

  .icon {
    margin-right: 8px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
