.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  outline: 0;

  .icon {
    margin-right: 8px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.primary {
  background-color: #2b6cde;
  color: #FFFFFF;
}

.secondary {
  background-color: rgba(43, 108, 222, 0.25);
  color: #2b6cde;
  border: 1px solid #2b6cde;
}

.danger {
  background-color: #FFFFFF;
  color: #EB5757;
  border: 1px solid #EB5757;
}

.close {
  background-color: #FFFFFF;
  color: #656565;
  border: 1px solid #C5C5C5;
}
